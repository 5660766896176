import React from 'react';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

export const LastSaturdays: React.FC = () => {
    const getLastSaturday = (year: number, month: number): Date => {
        const lastDayOfMonth = new Date(year, month + 1, 0); // Laatste dag van de maand
        lastDayOfMonth.setDate(
            lastDayOfMonth.getDate() -
                (lastDayOfMonth.getDay() === 6
                    ? 0
                    : (lastDayOfMonth.getDay() + 1) % 7),
        ); // Terug naar zaterdag
        return lastDayOfMonth;
    };

    const getAllLastSaturdaysFor2025 = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Alleen de datum vergelijken

        return Array.from({ length: 12 }, (_, month) =>
            getLastSaturday(2025, month),
        ).filter(
            date => date.getTime() > today.getTime() - 24 * 60 * 60 * 1000,
        ); // Alleen toekomstige datums tonen
    };

    const formatDate = (date: Date): string => {
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
        });
    };

    const lastSaturdays2025 = getAllLastSaturdaysFor2025();

    return (
        <Paragraph>
            <strong>2025 dates:</strong>
            {lastSaturdays2025.length > 0 &&
                lastSaturdays2025.map((date, index) => (
                    <React.Fragment key={index}>
                        <br />
                        {formatDate(date)}
                    </React.Fragment>
                ))}
        </Paragraph>
    );
};
