import React from 'react';
import { Title } from '../../elements/title/title';
import { Paragraph } from '../../elements/paragraph/paragraph';
import { ExternalLink } from '../../elements/external-link/external-link';

export const Impression = () => {
    return (
        <>
            <Title as="h2" text="impression" />
            <Paragraph>
                Check out YouTubers{' '}
                <ExternalLink
                    href="https://youtu.be/zX8HDHVXt_A?si=mK11KKdJy5MMsIpX"
                    text="InsideRob"
                />{' '}
                and{' '}
                <ExternalLink
                    href="https://youtu.be/45aX7aarKqU"
                    text="Patrick Marano"
                />{' '}
                as they tour and review our sauna!
            </Paragraph>
        </>
    );
};
