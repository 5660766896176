import React from 'react';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

export const SecondSundays: React.FC = () => {
    const getSecondSunday = (year: number, month: number): Date => {
        const firstDay = new Date(year, month, 1);
        const firstSunday = new Date(
            year,
            month,
            1 + ((7 - firstDay.getDay()) % 7),
        ); // Eerste zondag vinden
        return new Date(year, month, firstSunday.getDate() + 7); // Tweede zondag berekenen
    };

    const getAllSecondSundaysFor2025 = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Alleen de datum vergelijken zonder tijd

        return Array.from({ length: 12 }, (_, month) =>
            getSecondSunday(2025, month),
        ).filter(
            date => date.getTime() > today.getTime() - 24 * 60 * 60 * 1000,
        ); // Alleen toekomstige of actuele datums tonen
    };

    const formatDate = (date: Date): string => {
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
        });
    };

    const secondSundays2025 = getAllSecondSundaysFor2025();

    return (
        <Paragraph>
            <strong>2025 dates:</strong>
            {secondSundays2025.length > 0 &&
                secondSundays2025.map((date, index) => (
                    <React.Fragment key={index}>
                        <br />
                        {formatDate(date)}
                    </React.Fragment>
                ))}
        </Paragraph>
    );
};
